import React from "react";
import * as PropTypes from "prop-types";

const StepRow = ({name, href, children}) => (
    <tr>
        <td className="text-nowrap d-flex pr-2"><a href={href}>{name}</a></td>
        <td>{children}</td>
    </tr>
);

StepRow.propTypes = {
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};


export {StepRow};
