import React from "react";
import {Link} from "gatsby";
import {MdChevronRight} from "react-icons/md";

import Layout from "../components/layout";
import {StepRow} from "../components/table";
import ReleaseHistory from "../components/releaseHistory";
import {ServerBadgeList} from "../components/servers";
import {ConnectTutorialCode, InsertTutorialCode, SelectTutorialCode} from "../components/code";
import {ApiLink} from "../components/link";
import {KeyHeader} from "../components/typography";

const meta = [
    {
        name: 'keywords',
        content: '' +
            'library, C, C++, ' +
            'Oracle, SQL Server, SQLServer, DB2, Sybase, Informix, InterBase, SQLBase, MySQL, Postgre, ODBC, SQLite, SQL Anywhere, MariaDB, duckdb' +
            'client, database, programming, development, software, ' +
            'Win32, Windows, Linux, Unix, Solaris, AIX, Microsoft, Borland, Centura, Gupta, IBM'
    }
];

const StartHere = ({className}) => (
    <div className={className}>
        <Link className="btn btn-info mx-2 px-4" to="/HowTo/">Get Started</Link>
        <Link className="text-info mx-2" to="/ApiDoc/">
            Explore Docs
            <MdChevronRight size={24}/>
        </Link>
    </div>
);

const Hero = () => (
    <div className="jumbotron bg-dark text-light d-flex flex-column align-items-center">
        <div className="display-4 text-info">SQLAPI++</div>
        <div className="lead text-center mt-3">
            C++ library for interfacing with
            <br className="d-md-none"/>
            {' '}multiple SQL databases
        </div>
        <StartHere className="mt-5"/>
    </div>
);

const Footer = () => (
    <div className="d-flex flex-column align-items-center py-5">
        <StartHere/>
    </div>
);

const Page = () => (
    <Layout pageTitle="Home"
            meta={meta}
            hero={<Hero/>}
            mobileNav={false}
            pageFooter={<Footer/>}
    >
        <div className="feature-scroll feature-md-fill">
            <div className="mr-4">
                <h5 className="font-weight-light text-secondary mb-3">Portability</h5>
                <p>
                    SQLAPI++ is a C++ library that provides unified API to access different SQL databases.
                </p>

                <div>We support:</div>
                <div className="mb-3">
                    <ServerBadgeList/>
                </div>

                <p>
                    By encapsulating vendor APIs, SQLAPI++ library acts as middleware and
                    delivers database portability.
                </p>
            </div>

            <div className="mr-4">
                <h5 className="font-weight-light text-secondary mb-3">Easy to Use, Easy to Deploy</h5>
                <p>
                    Using the library saves you time and effort by taking away the complexity of native C APIs
                    provided by database clients.
                </p>
                <p>
                    No need to install and configure OLEDB and/or ODBC
                    drivers when developing and distributing your applications.
                </p>
            </div>

            <div>
                <h5 className="font-weight-light text-secondary mb-3">Extensibility and Support</h5>

                <p>
                    The library provides a low-level interface to access database-specific features
                    should that become necessary.
                </p>
                <p>
                    Library supports database connectivity on Windows, Linux/Unix and MacOS.
                </p>
                <p>SQLAPI++ ships with all the sources.</p>
                <p>We provide free ongoing email support, one year of bug fixing and new version updates.</p>
            </div>
        </div>

        <hr className="my-5"/>

        <KeyHeader>Connect to Database</KeyHeader>
        <p>
            Just make a call to {' '}<ApiLink>SAConnection::Connect</ApiLink>{' '} and provide the credentials.
            SQLAPI++ does the rest by dynamically loading specified client's native library
            and calling corresponding APIs.
        </p>
        <ConnectTutorialCode/>

        <KeyHeader>Insert Data into a Table</KeyHeader>
        <p>
            To insert data create SACommand, bind input variables as needed using stream operators
            and call {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} to send command to the server.
            SQLAPI++ takes care of preparing and executing the command using native APIs when needed.
        </p>
        <InsertTutorialCode/>

        <KeyHeader>Read Data from a Table</KeyHeader>
        <p>
            To read data execute a SELECT command, bind WHERE clause variables as needed and
            use {''}<ApiLink>SACommand::FetchNext</ApiLink> in a loop to retrieve all the rows.
            SQLAPI++ does the hard work of interfacing with native APIs, allocating buffers, etc.
        </p>
        <SelectTutorialCode/>

        <KeyHeader>More Examples</KeyHeader>
        <table>
            <tbody>
            <StepRow name="Example 1." href="./Examples/step1.cpp">Connecting to database (and error handling)</StepRow>
            <StepRow name="Example 2." href="./Examples/step2.cpp">Executing a simple SQL command</StepRow>
            <StepRow name="Example 3." href="./Examples/step3.cpp">Binding input parameters</StepRow>
            <StepRow name="Example 4." href="./Examples/step4.cpp">
                Executing a select query (and fetching result set)
            </StepRow>
            <StepRow name="Example 5." href="./Examples/step5.cpp">
                Binding LongBinary, LongChar, BLob and CLob data
            </StepRow>
            <StepRow name="Example 6." href="./Examples/step6.cpp">
                Fetching LongBinary, LongChar, BLob and CLob data
            </StepRow>
            <StepRow name="Example 7." href="./Examples/step7.cpp">
                Multi-threading support and canceling queries
            </StepRow>
            <StepRow name="Example 8." href="/Examples/bulk_fetch.cpp">Fetching records in bulk</StepRow>
            <StepRow name="Example 9." href="/Examples/refcursors.cpp">Using Oracle REF CURSORs</StepRow>
            <StepRow name="Example 10." href="/Examples/nestedcursors.cpp">Using Oracle nested cursors</StepRow>

            </tbody>
        </table>

        <KeyHeader>Compatibility</KeyHeader>
        <p>
            SQLAPI++ library supports the following C/C++ compilers:
        </p>
        <ul>
            <li>Microsoft Visual C++</li>
            <li>Embarcadero (Borland) C++</li>
            <li>GNU GCC C++ compiler</li>
            <li>Solaris Studio C++ compiler</li>
        </ul>

        <KeyHeader>Latest Release</KeyHeader>
        <ReleaseHistory limit={1}/>

        <p>See <Link to="/Download/history/">full history...</Link></p>
    </Layout>
);

export default Page;
